import * as Sentry from "@sentry/sveltekit";
import { pb } from "$lib/pocketbase";
import { currentUser } from "$lib/stores/users";
import { PUBLIC_CURRENT_ENV, PUBLIC_SENTRY_DSN } from "$env/static/public";

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [new Sentry.Replay()],
    ignoreErrors: ["ClientResponseError"]
})

pb.authStore.loadFromCookie(document.cookie);
pb.authStore.onChange(()=> {
    // on change of the auth store, update currentUser var
    currentUser.set(pb.authStore.model);

    document.cookie = pb.authStore.exportToCookie({ httpOnly: false, secure: PUBLIC_CURRENT_ENV === "prod" });
}, true);

// TODO
// https://devdojo.com/jeblister/how-to-setup-an-authentication-user-management-on-sveltekit-using-pocketbase
export const handleError = Sentry.handleErrorWithSentry();